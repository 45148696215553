import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import axios from 'axios';
import moment from 'moment';

import 'antd/dist/antd.min.css';

// import { useThemeSwitcher } from "react-css-theme-switcher";

import { FireOutlined, GlobalOutlined, SendOutlined, ClearOutlined, ArrowRightOutlined, UndoOutlined, RedoOutlined, FileSearchOutlined, PieChartOutlined, SettingOutlined } from '@ant-design/icons';
import { Layout, message, Typography, Row, Col, Button, Spin, DatePicker, Form, Checkbox, Menu, Tree, Select, Divider, Modal, Card, Space, Tag, notification } from 'antd';

import UserLogoutButton from "./UserLogoutButton";
import Statistics from "./Statistics";
import Report from "./reporting/Report";
import Overview from "./overview/GraphicalOverview";
import AdminView from "./admin_view/AdminView";
import FilterDrawer from "./reporting/FilterDrawerButton";
import AccountSubTable from "./reporting/AccountSubTable";
// import ComboBox from "./components/ComboBox";
import FiltersTransfer from "./components/FiltersTransfer";
import ClearResetFiltersButton from "./components/ClearResetFiltersButton";
import ViewDataWarning from "./components/ViewDataWarning";
// import DisplayBox from "./reporting/DisplayBox";
import {UserContext} from './user-context';


const { Header, Content, Sider, Footer } = Layout;
const { Title, Text } = Typography;
// const { SHOW_PARENT, SHOW_ALL, SHOW_CHILD } = TreeSelect;

const appBarHeight = 68;
const drawerHeight = 235;

const dateFormat = 'DD/MM/YYYY';

const tagCountFilter = 6

function useWindowHeight() {
    const [height, setHeight] = useState([window.innerHeight - 1, window.outerHeight - 1]);
    useLayoutEffect(() => {
        function updateSize() {
            setHeight([window.innerHeight - 1, window.outerHeight - 1]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return height;
}

function useWindowWidth() {
    const [width, setWidth] = useState([window.innerWidth - 1, window.outerWidth - 1]);
    useLayoutEffect(() => {
        function updateSize() {
            setWidth([window.innerWidth - 1, window.outerWidth - 1]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return width;
}

export default function App(){

    const { route, role, setRole, setRoute } = useContext(UserContext);

    // const { switcher, themes, currentTheme } = useThemeSwitcher();
    const currentTheme = "light"
    // const [switchingTheme, setSwitchingTheme] = useState(false);

    // APP CONTROLS

    // statistics to display in the appbar
    const [statistics, setStatistics] = useState(null)

    // trigger to filter data in correct moment
    const [triggerApplyFilters, toggleTriggerApplyFilters] = useState(false)

    // Allow user to view non-gdpr messsages
    const [showNonGdprMessages, setShowNonGdprMessages] = useState(false)

    // Allow user to view non-gdpr components
    const [showNonGdprComponents, setShowNonGdprComponents] = useState(false)

    // Sidebar menu controls
    const [collapsed, setCollapsed] = useState(true)
    const [selected, setSelected] = useState("graphical-view")
    const [openKeys, setOpenKeys] = useState([])

    // style state - keep track of viewport height
    const [intFrameHeight, intOuterFrameHeight] = useWindowHeight();
    const [intFrameWidth, intOuterFrameWidth] = useWindowWidth();

    // display initial data loading
    const [initializing, setInitializing] = useState(false);
    // const [initializingText, setInitializingText] = useState("Initializing report...")

    //////////////////////////////////////////// REPORT

    // display data loading
    const [loadingReport, setLoadingReport] = useState(false);
    // const [loadingRecords, setLoadingRecords] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);

    // report data
    const [reportData, setReportData] = useState([]);

    // all filters to keep track of the requests
    const [areas, setAreas] = useState([]);
    const [categories, setCategories] = useState([]);
    const [classifications, setClassifications] = useState([]);
    const [minFrom, setMinFrom] = useState(null);
    const [maxTo, setMaxTo] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [accountsLike, setAccountsLike] = useState([]);
    const [senders, setSenders] = useState([]);
    const [senderDomains, setSenderDomains] = useState([]);
    const [sendersLike, setSendersLike] = useState([]);
    const [contentTypes, setContentTypes] = useState([]);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [filterTree, setFilterTree] = useState([])
    const [treeLookup, setTreeLookup] = useState([]) // tree lookup

    // filter obj to save last searched query (modified on "Apply Filters")
    const [filterState, setFilterState] = useState(null)

    // initial filters to handle resetting
    const [initialState, setInitialState] = useState(null)

    // flag to keep track of applied filters - reset when clicking "reset filters"
    const [filtersAppliedFlag, setFiltersAppliedFlag] = useState(false)

    // form hook to control tree selection
    const [form] = Form.useForm();

    ///////////////////////////////////////////////////// OVERVIEW

    // overview data
    const [overviewData, setOverviewData] = useState([])
    const [graphData, setGraphData] = useState([])
    const [subGraphData, setSubGraphData] = useState([])
    // initial data
    const [initData, setInitData] = useState(null)
    // loading indicators
    const [loading, setLoading] = useState(false)
    const [loadingSub, setLoadingSub] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [loadingStatistics, setLoadingStatistics] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const [viewDataSuperusers, setViewDataSuperusers] = useState([])
    const [blockedViewRightsAcc, setBlockedViewRightsAcc] = useState([])

    // Sidebar menu functions
    const onCollapse = collapsed => {
        setCollapsed(collapsed)
    };

    const onOpenChange = newOpenKeys => {
        if (newOpenKeys.length <= 1) {setOpenKeys(newOpenKeys)}
        else {
            const latestOpenKey = newOpenKeys.find(key => openKeys.indexOf(key) === -1);
            setOpenKeys([latestOpenKey])
        }
    };

    const handleClick = e => {
        if (e.key === 'report-view' && reportData.length === 0 && !loadingReport) {
            setDrawerVisible(true)
        } else {
            setDrawerVisible(false)
        }
        // For now reset overview data when switching back to overview to prevent pie chart selection bugs - TODO need to control selection!
        if (e.key === 'graphical-view') {
            setOverviewData(initData.data)
            setGraphData(initData.graph_data)
            setSubGraphData(initData.sub_graph_data)
        }
        setSelected(e.key)
    }

    // INITIAL DATA FETCHING

    const fetchInitialData = (routeOverwritten) => {
        const fetchRoute = !!routeOverwritten ? routeOverwritten : route
        // const axios1 = axios.get(fetchRoute + '/Report/Overview')
        // const axios2 = axios.get(fetchRoute + '/Report/Filters')
        // const axios3 = axios.get(fetchRoute + '/Report/Statistics')
        const axios1 = axios({method: 'get', url: fetchRoute + '/Report/Overview', timeout: 2*60*1000})
        const axios2 = axios({method: 'get', url: fetchRoute + '/Report/Filters', timeout: 2*60*1000})
        const axios3 = axios({method: 'get', url: fetchRoute + '/Report/Statistics', timeout: 2*60*1000})
        const axios4 = axios({method: 'get', url: fetchRoute + '/Report/ViewDataSuperusers', timeout: 2*60*1000})
        setInitializing(true)
        axios.all([axios1, axios2, axios3, axios4])
            .then(axios.spread((...responses) => {
                const responseOverview = responses[0]
                const responseFilters = responses[1]
                const responseStatistics = responses[2]
                const responseSuperusers = responses[3]
                if (responseOverview.status === 200 && responseFilters.status === 200 && responseStatistics.status === 200 && responseSuperusers.status === 200){
                    // statistics
                    setStatistics(responseStatistics.data)
                    // overview
                    setInitData(responseOverview.data)
                    setOverviewData(responseOverview.data.data)
                    setGraphData(responseOverview.data.graph_data)
                    setSubGraphData(responseOverview.data.sub_graph_data)
                    // report
                    setAreas(responseFilters.data.areas)
                    setCategories(responseFilters.data.categories)
                    setClassifications(responseFilters.data.classifications)
                    setMinFrom(!!responseFilters.data.min_date ? moment(responseFilters.data.min_date, 'DD/MM/YYYY') : null)
                    setMaxTo(!!responseFilters.data.max_date ? moment(responseFilters.data.max_date, 'DD/MM/YYYY'): null)
                    setDateFrom(!!responseFilters.data.min_date ? moment(responseFilters.data.min_date, 'DD/MM/YYYY') : null)
                    setDateTo(!!responseFilters.data.max_date ? moment(responseFilters.data.max_date, 'DD/MM/YYYY'): null)
                    setAccounts(responseFilters.data.accounts)
                    setSenders(responseFilters.data.senders)
                    setSenderDomains(responseFilters.data.sender_domains)
                    setContentTypes(responseFilters.data.content_types)
                    setFilterTree(responseFilters.data.tree)
                    setTreeLookup(responseFilters.data.tree_data)
                    setInitialState({
                        areas: responseFilters.data.areas,
                        categories: responseFilters.data.categories,
                        classifications: responseFilters.data.classifications,
                        dateFrom: !!responseFilters.data.min_date ? moment(responseFilters.data.min_date, 'DD/MM/YYYY') : null,
                        dateTo: !!responseFilters.data.max_date ? moment(responseFilters.data.max_date, 'DD/MM/YYYY'): null,
                        accounts: responseFilters.data.accounts,
                        senders: responseFilters.data.senders,
                        senderDomains: responseFilters.data.sender_domains,
                        content_types: responseFilters.data.content_types,
                        accountsLike: [],
                        sendersLike: []
                    })
                    setFilterState({
                        areas: responseFilters.data.areas,
                        categories: responseFilters.data.categories,
                        classifications: responseFilters.data.classifications,
                        dateFrom: !!responseFilters.data.min_date ? moment(responseFilters.data.min_date, 'DD/MM/YYYY') : null,
                        dateTo: !!responseFilters.data.max_date ? moment(responseFilters.data.max_date, 'DD/MM/YYYY'): null,
                        accounts: responseFilters.data.accounts,
                        senders: responseFilters.data.senders,
                        senderDomains: responseFilters.data.sender_domains,
                        content_types: responseFilters.data.content_types,
                        accountsLike: [],
                        sendersLike: []
                    })
                    setViewDataSuperusers(responseSuperusers.data.superusers)
                } else {
                    message.error("Initial data fetch failed, check your access rights and application status...")
                }
                setInitializing(false)
            }))
            .catch(error => {
                message.error("Initial data fetch failed, check your access rights and application status...")
                console.log(error);
                setInitializing(false)
            });
        if (window.o365_config.USE_DEVELOPMENT_BUILD) {
            setInitData(window.o365_config.data.data_dict)
            setOverviewData(window.o365_config.data.data_dict.data)
            setGraphData(window.o365_config.data.data_dict.graph_data)
            setSubGraphData(window.o365_config.data.data_dict.sub_graph_data)
            setStatistics(window.o365_config.data.stats_dict)
            // filters
            const responseFilters = {"data": window.o365_config.data.filters_dict}
            setAreas(responseFilters.data.areas)
            setCategories(responseFilters.data.categories)
            setClassifications(responseFilters.data.classifications)
            setMinFrom(!!responseFilters.data.min_date ? moment(responseFilters.data.min_date, 'DD/MM/YYYY') : null)
            setMaxTo(!!responseFilters.data.max_date ? moment(responseFilters.data.max_date, 'DD/MM/YYYY'): null)
            setDateFrom(!!responseFilters.data.min_date ? moment(responseFilters.data.min_date, 'DD/MM/YYYY') : null)
            setDateTo(!!responseFilters.data.max_date ? moment(responseFilters.data.max_date, 'DD/MM/YYYY'): null)
            setAccounts(responseFilters.data.accounts)
            setSenders(responseFilters.data.senders)
            setSenderDomains(responseFilters.data.sender_domains)
            setContentTypes(responseFilters.data.content_types)
            setFilterTree(responseFilters.data.tree)
            setTreeLookup(responseFilters.data.tree_data)
            setInitialState({
                areas: responseFilters.data.areas,
                categories: responseFilters.data.categories,
                classifications: responseFilters.data.classifications,
                dateFrom: !!responseFilters.data.min_date ? moment(responseFilters.data.min_date, 'DD/MM/YYYY') : null,
                dateTo: !!responseFilters.data.max_date ? moment(responseFilters.data.max_date, 'DD/MM/YYYY'): null,
                accounts: responseFilters.data.accounts,
                senders: responseFilters.data.senders,
                senderDomains: responseFilters.data.sender_domains,
                content_types: responseFilters.data.content_types,
                accountsLike: [],
                sendersLike: []
            })
            setFilterState({
                areas: responseFilters.data.areas,
                categories: responseFilters.data.categories,
                classifications: responseFilters.data.classifications,
                dateFrom: !!responseFilters.data.min_date ? moment(responseFilters.data.min_date, 'DD/MM/YYYY') : null,
                dateTo: !!responseFilters.data.max_date ? moment(responseFilters.data.max_date, 'DD/MM/YYYY'): null,
                accounts: responseFilters.data.accounts,
                senders: responseFilters.data.senders,
                senderDomains: responseFilters.data.sender_domains,
                content_types: responseFilters.data.content_types,
                accountsLike: [],
                sendersLike: []
            })
        }
    }

    // REPORT DATA FETCHING

    // const fetchOnlyData = () => {
    //     setLoadingReport(true)
    //     axios.get(route + '/Report/Data', {params: {view_non_gdpr: showNonGdprMessages}})
    //         .then(response => {
    //             if (response.status === 200){
    //                 setReportData(response.data)
    //             } else {
    //                 message.error("ERROR ERROR ERROR!")
    //                 console.log(response);
    //             }
    //             setLoadingReport(false)
    //         })
    //         .catch(error => {
    //             message.error("ERROR ERROR!")
    //             console.log(error);
    //             setLoadingReport(false)
    //         });
    //     // setReportData(report_data_new)
    // }

    const clickUserPeriodCategory = (user, period, categoryVal, areaList, locations) => {
        // console.log(user, period, categoryVal, areaList)
        setLoadingReport(true)
        // Reset filters
        setAreas(initialState.areas)
        setCategories(initialState.categories)
        setClassifications(initialState.classifications)
        setDateFrom(initialState.dateFrom)
        setDateTo(initialState.dateTo)
        // handle content types
        let newContTypes = []
        if (locations.length === 0) newContTypes = ['sharepoint', 'onedrive', 'body', 'attachment'] // consider initialState.content_types...
        else {
            if (locations.includes('sharepoint')) newContTypes.push('sharepoint')
            if (locations.includes('onedrive')) newContTypes.push('onedrive')
            if (locations.includes('mail')) newContTypes.push('body', 'attachment')
        }
        setContentTypes(newContTypes)
        // setContentTypes(initialState.content_types)
        // handle accounts
        setAccounts(!!user ? [user] : initialState.accounts)
        setSenders(initialState.senders)
        setSenderDomains(initialState.senderDomains)
        // Reset filter query
        // console.log({...initialState})
        setFilterState({
            ...initialState,
            content_types: newContTypes,
            accounts: !!user ? [user] : initialState.accounts, 
            areas: areaList.length > 0 ? areaList : initialState.areas, 
            categories: categoryVal != null ? [categoryVal] : initialState.categories 
        })
        if (areaList.length > 0) {
            setFiltersAppliedFlag(true)
            setAreas(areaList)
            // setFilterState({...filterState, areas: areaList})
            // TODO - set date from and date to...
        }
        if (categoryVal != null) {
            setFiltersAppliedFlag(true)
            setCategories([categoryVal])
        }
        // Use tree lookup to select appropriate classifications
        // DevTip - for val of list!!! (val)
        // DevTip - for val in object!!! (val, object[val])

        // TODO - disable until uniqueness is not fixed
        // if (areaList.length > 0) {
        //     let cls = []
        //     for (const categoryKey in treeLookup){
        //         for (const key in treeLookup[categoryKey]) {
        //             if (areaList.includes(key)) cls = cls.concat(treeLookup[categoryKey][key])
        //         }
        //     }
        //     setClassifications(cls)

        // } else if (categoryVal != null) {
        //     let cls = []
        //     for (const key in treeLookup[categoryVal]) {
        //         cls = cls.concat(treeLookup[categoryVal][key])
        //     }
        //     setClassifications(cls)
        // }

        setSelected('report-view')
        // Fetch data
        fetchDataUserPeriod(user, period, categoryVal, areaList, newContTypes)
    }

    const fetchDataUserPeriod = (user, period, categoryVal, areaList, newContentTypes) => {
        // filterState handled before function call
        setLoadingReport(true)
        let update = [];
        update.push({
            "accounts": !!user ? [user] : accounts,
            "accounts_like": initialState.accountsLike,
            "senders": initialState.senders,
            "sender_domains": initialState.senderDomains,
            "senders_like": initialState.sendersLike,
            "areas": areaList.length > 0 ? areaList : initialState.areas, // "areas" is not visible yet...
            "categories": categoryVal != null ? [categoryVal] : initialState.categories, // "categories" might not be visible yet as well...
            "classifications": initialState.classifications,
            "content_types": newContentTypes,
            "from": initialState.dateFrom == null ? null : initialState.dateFrom.format('DD/MM/YYYY'),
            "to": initialState.dateTo == null ? null : initialState.dateTo.format('DD/MM/YYYY'),
            "view_non_gdpr": showNonGdprMessages,
            "user": user,
            "period": period
        })
        update = JSON.stringify(update);

        const data = new FormData();
        data.append('data', update)

        axios.post(route + '/Report/CheckConsent', data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
        })
        .then(response => {
            if (response.status === 200){
                console.log(response.data.data)
                console.log(response.data.data.length)
                // check if length of the reponse.data is > 0 and display a top notification that the data could not be loaded for the acounts in response.data
                setBlockedViewRightsAcc(response.data.data)
                if (response.data.data.length > 0) {
                    api.open({
                        message: 'View rights required',
                        description: <>
                            <p>You don't have the required data viewing rights of chosen account(s). Data Viewing Rights may be managed in the Configuration Page.</p>
                            <a onClick={() => showAccounts(response.data.data)}>Click to view the accounts list</a>
                        </>,
                        placement: 'top'
                    });
                }
            } else {
                message.error("Could not check data view consent...")
                console.log(response)
            }
        })
        .catch(error => {
            message.error("Could not check data view consent...")
            console.log(error)
        })

        axios.post(route + '/Report/DataFiltered', data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
        })
            .then(response => {
                if (response.status === 200){
                    setReportData(response.data)
                } else {
                    message.error("Could not fetch data...")
                    console.log(response);
                }
                setLoadingReport(false)
            })
            .catch(error => {
                message.error("Could not fetch data...")
                console.log(error);
                setLoadingReport(false)
            });
            if (window.o365_config.USE_DEVELOPMENT_BUILD) setReportData(window.o365_config.data.report_data_new)
    }

    const showAccounts = (accounts) => {
        api.open({
            message: 'Accounts:',
            description: <ul>{accounts.map(account => <li>{account}</li>)}</ul>,
            placement: 'top',
            style: {
                maxHeight: '60vh',
                overflow: 'auto',
            }
        });
      }

    const applyFilters = () => {
        setLoadingReport(true)
        setFiltersAppliedFlag(true)
        // update filterState
        // SPLIT CLASSIFICATION LIST INTO PROPER LISTS
        var cat_list = []
        var area_list = []
        var cls_list = []
        classifications.forEach(cls => {
            if (initialState.categories.includes(cls)) cat_list.push(cls)
            else if (initialState.areas.includes(cls)) area_list.push(cls)
            else cls_list.push(cls)
        })
        // TODO - remove once classification uniqueness is fixed
        cls_list = []
        initialState.classifications.forEach(cls => {
            if (!(initialState.categories.includes(cls) || initialState.areas.includes(cls))) cls_list.push(cls)
        })
        setAreas(area_list)
        setCategories(cat_list)
        // save filter state
        setFilterState({
            accounts: accounts,
            senders: senders,
            senderDomains: senderDomains,
            areas: area_list,
            categories: cat_list,
            classifications: [...area_list, ...cat_list, ...cls_list],
            content_types: contentTypes,
            dateFrom: dateFrom,
            dateTo: dateTo,
            accountsLike: accountsLike,
            sendersLike: sendersLike
        })
        let update = [];
        update.push({
            "accounts": accounts,
            "accounts_like": accountsLike,
            "senders": senders,
            "sender_domains": senderDomains,
            "senders_like": sendersLike,
            "areas": area_list,
            "categories": cat_list,
            "classifications": cls_list,
            "content_types": contentTypes,
            "from": dateFrom == null ? null : dateFrom.format('DD/MM/YYYY'),
            "to": dateTo == null ? null : dateTo.format('DD/MM/YYYY'),
            "view_non_gdpr": showNonGdprMessages
        })
        update = JSON.stringify(update);

        const data = new FormData();
        data.append('data', update)

        // Check consent before fetching data
        axios.post(route + '/Report/CheckConsent', data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
        })
        .then(response => {
            if (response.status === 200){
                console.log(response.data.data)
                console.log(response.data.data.length)
                setBlockedViewRightsAcc(response.data.data)
                // check if length of the reponse.data is > 0 and display a popup that the data could not be loaded for the acounts in response.data
                if (response.data.data.length > 0) {
                    console.log(response.data)
                    api.open({
                        message: 'View rights required',
                        description: <>
                            <p>You don't have the required data viewing rights of chosen account(s). Data Viewing Rights may be managed in the Configuration Page.</p>
                            <a onClick={() => showAccounts(response.data.data)}>Click to view the accounts list</a>
                        </>,
                        placement: 'top'
                    });
                }
            } else {
                message.error("Could not check data view consent...")
                console.log(response)
            }
        })
        .catch(error => {
            message.error("Could not check data view consent...")
            console.log(error)
        })

        axios.post(route + '/Report/DataFiltered', data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
        })
        .then(response => {
            if (response.status === 200){
                setReportData(response.data)
                // trigger sub table data reload                
                toggleTriggerApplyFilters(!triggerApplyFilters)
                // close filter modal
                setDrawerVisible(false)
            } else {
                message.error("Could not fetch data...")
                console.log(response)
            }
            setLoadingReport(false)
        })
        .catch(error => {
            message.error("Could not fetch data...")
            console.log(error)
            setLoadingReport(false)
        })
        if (window.o365_config.USE_DEVELOPMENT_BUILD) setReportData(window.o365_config.data.report_data_new)
    }

    // OVERVIEW DATA FETCHING

    const fetchOverviewData = () => {
        setLoading(true)
        setLoadingStatistics(true)
        axios.get(route + '/Report/Overview')
            .then(response => {
                if (response.status === 200){
                    setInitData(response.data)
                    setOverviewData(response.data.data)
                    setGraphData(response.data.graph_data)
                    setSubGraphData(response.data.sub_graph_data)
                } else {
                    message.error("Could not fetch data...")
                    console.log(response);
                }
                setLoading(false)
            })
            .catch(error => {
                message.error("Could not fetch data...")
                console.log(error);
                setLoading(false)
            });
        // Also fetch statistics
        axios.get(route + '/Report/Statistics')
            .then(response => {
                if (response.status === 200){
                    setStatistics(response.data)
                } else {
                    message.error("Could not fetch statistics...")
                    console.log(response);
                }
                setLoadingStatistics(false)
            })
            .catch(error => {
                message.error("Could not fetch statistics...")
                console.log(error);
                setLoadingStatistics(false)
            });
        // Also fetch superusers
        axios.get(route + '/Report/ViewDataSuperusers')
            .then(response => {
                if (response.status === 200){
                    setViewDataSuperusers(response.data.superusers)
                } else {
                    message.error("Could not fetch superusers...")
                    console.log(response);
                }
            })
            .catch(error => {
                message.error("Could not fetch superusers...")
                console.log(error);
            });
        if (window.o365_config.USE_DEVELOPMENT_BUILD) {
            setInitData(window.o365_config.data.data_dict)
            setOverviewData(window.o365_config.data.data_dict.data)
            setGraphData(window.o365_config.data.data_dict.graph_data)
            setSubGraphData(window.o365_config.data.data_dict.sub_graph_data)
        }
    }

    const fetchOverviewDataFiltered = (filters, locations) => {
        // handle content types
        let newContTypes = []
        if (locations.length === 0) newContTypes = ['sharepoint', 'onedrive', 'body', 'attachment'] // consider initialState.content_types...
        else {
            if (locations.includes('sharepoint')) newContTypes.push('sharepoint')
            if (locations.includes('onedrive')) newContTypes.push('onedrive')
            if (locations.includes('mail')) newContTypes.push('body', 'attachment')
        }
        setLoadingData(true)
        axios.get(route + '/Report/Overview/Filtered', {params: {area: filters, content_types: newContTypes}})
            .then(response => {
                if (response.status === 200){
                    setOverviewData(response.data)
                } else {
                    message.error("ERROR!")
                    console.log(response);
                }
                setLoadingData(false)
            })
            .catch(error => {
                message.error("ERROR!!")
                console.log(error);
                setLoadingData(false)
            });
        // setOverviewData(dataToSet)
    }

    const fetchOverviewFiltered = (locations) => {
        // handle content types
        let newContTypes = []
        if (locations.length === 0) newContTypes = ['sharepoint', 'onedrive', 'body', 'attachment'] // consider initialState.content_types...
        else {
            if (locations.includes('sharepoint')) newContTypes.push('sharepoint')
            if (locations.includes('onedrive')) newContTypes.push('onedrive')
            if (locations.includes('mail')) newContTypes.push('body', 'attachment')
        }
        setLoadingData(true)
        axios.get(route + '/Report/Overview/FilteredAll', {params: {content_types: newContTypes}})
            .then(response => {
                if (response.status === 200){
                    setOverviewData(response.data.data)
                    setGraphData(response.data.graph_data)
                    setSubGraphData(response.data.sub_graph_data)
                } else {
                    message.error("ERROR!")
                    console.log(response);
                }
                setLoadingData(false)
            })
            .catch(error => {
                message.error("ERROR!!")
                console.log(error);
                setLoadingData(false)
            });
        // setOverviewData(dataToSet)
    }

    const fetchSubGraphData = (filters, locations) => {
        let newFilters = !!filters ? filters : initialState.categories
        // handle content types
        let newContTypes = []
        if (locations.length === 0) newContTypes = ['sharepoint', 'onedrive', 'body', 'attachment'] // consider initialState.content_types...
        else {
            if (locations.includes('sharepoint')) newContTypes.push('sharepoint')
            if (locations.includes('onedrive')) newContTypes.push('onedrive')
            if (locations.includes('mail')) newContTypes.push('body', 'attachment')
        }
        setLoadingSub(true)
        setLoadingData(true)
        axios.get(route + '/Report/Overview/Graph', {params: {category: newFilters, content_types: newContTypes}})
            .then(response => {
                if (response.status === 200){
                    setSubGraphData(response.data.sub_graph_data)
                    setOverviewData(response.data.data)
                } else {
                    message.error("ERROR!")
                    console.log(response);
                }
                setLoadingSub(false)
                setLoadingData(false)
            })
            .catch(error => {
                message.error("ERROR!!")
                console.log(error);
                setLoadingSub(false)
                setLoadingData(false)
            });
    }


    // acc or fetchAll -> csv clicked in the overview
    // acc None and fetchAll false -> csv clicked in the report, thus all filters need to be applied
    // TODO Consider dividing into two functions
    const generateCsv = (acc, fetchAll, categoryVal, areaList, locations) => {
        message.info("Generating CSV file...")
        // handle content types
        let newContTypes = []
        if (locations.length === 0) newContTypes = ['sharepoint', 'onedrive', 'body', 'attachment'] // consider initialState.content_types...
        else {
            if (locations.includes('sharepoint')) newContTypes.push('sharepoint')
            if (locations.includes('onedrive')) newContTypes.push('onedrive')
            if (locations.includes('mail')) newContTypes.push('body', 'attachment')
        }
        let update = [];
        update.push({
            "accounts": !!acc ? [acc] : fetchAll ? initialState.accounts : accounts,
            "senders": (!!acc || fetchAll) ? initialState.senders : senders,
            "areas": (!!acc || fetchAll) 
                ? areaList.length > 0 ? areaList : initialState.areas 
                : areas,
            "categories": (!!acc || fetchAll) 
                ? categoryVal != null ? [categoryVal] : initialState.categories
                : categories,
            "classifications": (!!acc || fetchAll) ? initialState.classifications : classifications,
            "content_types": (!!acc || fetchAll) ? newContTypes : contentTypes,
            "from": (!!acc || fetchAll)
              ? minFrom == null ? null : minFrom.format('DD/MM/YYYY')
              : dateFrom == null ? null : dateFrom.format('DD/MM/YYYY'),
            "to": (!!acc || fetchAll)
              ? maxTo == null ? null : maxTo.format('DD/MM/YYYY')
              : dateTo == null ? null : dateTo.format('DD/MM/YYYY'),
            "view_non_gdpr": showNonGdprMessages
        })
        update = JSON.stringify(update);

        const data = new FormData();
        data.append('data', update)

        axios.post(route + '/Report/Csv', data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
        })
            .then(response => {
                if (response.status === 200){
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ReportData.csv'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    message.success('CSV generated!')
                } else {
                    message.error("Failed to generate csv...")
                    console.log(response);
                }
            })
            .catch(error => {
                message.error("Could not generate csv...")
                console.log(error);
            });
    }


    useEffect(() => {
        fetchInitialData(null)
    }, [])

    // const toggleTheme = () => {
    //     switcher({ theme: currentTheme === 'light' ? themes.dark : themes.light });
    // };

    const resetFilters = () => {
        // fetchOnlyData()
        setAreas(initialState.areas)
        setCategories(initialState.categories)
        setClassifications(initialState.classifications)
        // trick to force tree select to show accept the value
        // form.setFieldsValue({ tree_filter: initialState.classifications });
        form.setFieldsValue({ accounts_test: initialState.accountsLike, senders_test: initialState.sendersLike})
        setDateFrom(initialState.dateFrom)
        setDateTo(initialState.dateTo)
        setAccounts(initialState.accounts)
        setAccountsLike(initialState.accountsLike)
        setSenders(initialState.senders)
        setSenderDomains(initialState.senderDomains)
        setSendersLike(initialState.sendersLike)
        setContentTypes(initialState.content_types)
        setFiltersAppliedFlag(false)
        // setFilterState(initialState)
        // toggleTriggerApplyFilters(!triggerApplyFilters)
    }

    const restoreFilters = () => {
        // console.log(filterState)
        setAreas(filterState.areas)
        setCategories(filterState.categories)
        setClassifications(filterState.classifications)
        // trick to force tree select to show accept the value
        // form.setFieldsValue({ tree_filter: filterState.classifications });
        form.setFieldsValue({ accounts_test: filterState.accountsLike, senders_test: filterState.sendersLike})
        setDateFrom(filterState.dateFrom)
        setDateTo(filterState.dateTo)
        setAccounts(filterState.accounts)
        setAccountsLike(filterState.accountsLike)
        setSenders(filterState.senders)
        setSenderDomains(filterState.senderDomains)
        setSendersLike(filterState.sendersLike)
        setContentTypes(filterState.content_types)
    }

    const clearFilters = () => {
        setAreas([])
        setCategories([])
        setClassifications([])
        // trick to force tree select to show accept the value
        // form.setFieldsValue({ tree_filter: [] });
        form.setFieldsValue({ accounts_test: [], senders_test: []})
        setDateFrom(initialState.dateFrom)
        setDateTo(initialState.dateTo)
        setAccounts([])
        setSenders([])
        setSenderDomains([])
        setContentTypes([])
        setFiltersAppliedFlag(false)
    }

    const clearFilter = (filter) => {
        if (filter === "areas"){
            setAreas([])
        } else if (filter === "categories"){
            setCategories([])
        } else if (filter === "classifications"){
            setClassifications([])
        } else if (filter === "accounts"){
            setAccounts([])
        } else if (filter === "accounts_test"){
            setAccountsLike([])
            form.setFieldsValue({ accounts_test: []})
        } else if (filter === "senders"){
            setSenders([])
        } else if (filter === "sender_domains"){
            setSenderDomains([])
        } else if (filter === "senders_test"){
            setSendersLike([])
            form.setFieldsValue({ senders_test: []})
        } else if (filter === "content_types"){
            setContentTypes([])
        }
    }

    const resetFilter = (filter) => {
        if (filter === "areas"){
            setAreas(initialState.areas)
        } else if (filter === "categories"){
            setCategories(initialState.categories)
        } else if (filter === "classifications"){
            setClassifications(initialState.classifications)
        } else if (filter === "accounts"){
            setAccounts(initialState.accounts)
        } else if (filter === "accounts_test"){
            setAccountsLike(initialState.accountsLike)
            form.setFieldsValue({ accounts_test: initialState.accountsLike})
        } else if (filter === "senders"){
            setSenders(initialState.senders)
        } else if (filter === "sender_domains"){
            setSenderDomains(initialState.senderDomains)
        } else if (filter === "senders_test"){
            setSendersLike(initialState.sendersLike)
            form.setFieldsValue({ senders_test: initialState.sendersLike})
        } else if (filter === "content_types"){
            setContentTypes(initialState.content_types)
        } else if (filter === "date_range"){
            setDateFrom(initialState.dateFrom)
            setDateTo(initialState.dateTo)
        }
    }

    // const changeFilters = (value) => {
    //     console.log(value)
    //     let filters = {
    //       "Area": [],
    //       "Category": [],
    //       "Classification": []
    //     }
    //     value.map(item => {
    //       console.log(item)
    //       filters[filterLookup[item]].push(item)
    //       if (filterLookup[item] === 'Category'){
    //         for (const [ar, catDict] of Object.entries(filterDict)) {
    //             if (Object.keys(catDict).includes(item)){
    //               filters['Classification'] = filters['Classification'].concat(catDict[item])
    //               break
    //             }
    //         }
    //       } else if (filterLookup[item] === 'Area'){
    //           for (const [cat, classList] of Object.entries(filterDict[item])) {
    //             filters['Category'].push(cat)
    //             filters['Classification'] = filters['Classification'].concat(classList)
    //           }
    //       }
    //       // console.log(Object.keys(item)[0])
    //       // console.log(Object.values(item)[0])
    //       // console.log(filters[Object.keys(item[0])[0]])
    //       // filters[Object.keys(item)[0]].push(Object.values(item)[0])
    //     })
    //     console.log(filters)
    //     setAreas(filters["Area"])
    //     setCategories(filters["Category"])
    //     setClassifications(filters["Classification"])
    // }

    // const comboContentFilter = <ComboBox setFilters={setContentTypes} values={contentTypes} lookupValues={(!!initialState && !!initialState.content_types) ? initialState.content_types : []}/>

    // const themeSwitchButton =
    //     <Button
    //         // icon={currentTheme === 'dark' ? <FireOutlined /> : <GlobalOutlined />}
    //         type="primary"
    //         size="large"
    //         style={currentTheme === 'dark'
    //             ? {background: '#b0afae', borderColor: '#b0afae', color: '#303030'}
    //             : {background: '#101010', borderColor: '#101010'}
    //         }
    //         onClick={() => toggleTheme()}
    //     >
    //         {currentTheme === 'dark' ? <FireOutlined style={{fontSize: 20}}/> : <GlobalOutlined style={{fontSize: 20}} />}
    //     </Button>

    const applyFiltersButton = <Button onClick={applyFilters} icon={<SendOutlined />} type="primary" style={{marginRight: 4, marginTop: 4}}>Apply Filters</Button>

    const resetFiltersButton = <Button onClick={resetFilters} icon={<RedoOutlined />} type="default" style={{marginRight: 4, marginTop: 4}}>Reset Filters</Button>

    const clearFiltersButton = <Button onClick={clearFilters} icon={<ClearOutlined />} type="default" style={{marginRight: 4, marginTop: 4}}>Clear Filters</Button>

    const restoreFiltersButton = <Button onClick={restoreFilters} icon={<UndoOutlined />} type="default" style={{marginRight: 4, marginTop: 4}}>Restore To Current Filters</Button>

    const handleDelete = (key, deleteData) => {
        setLoading(true)
        deleteData = JSON.stringify(deleteData);
        
        const data = new FormData();
        data.append('data', deleteData)
        console.log(data)
        
        axios.post(route + '/Report/Quarantine', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(response => {
            if (response.status === 200){
                const newData = reportData.filter((item) => !key.includes(item.key));
                setReportData(newData);
                if (Object.keys(response.data.failed_to_move).length > 0) {
                    message.warning("Some data could not be moved to quarantine...")
                    // display worning with the information from response.data.failed_to_move
                    api.open({
                        message: 'Failed to move to quarantine',
                        description: <ul>{Object.values(response.data.failed_to_move).map(item => 
                            Object.keys(item).map(key => <li>{key} - {item[key]}</li>))}</ul>,
                        placement: 'top',
                        style: {
                            maxHeight: '60vh',
                            overflow: 'auto',
                        }
                    });
                }
            } else {
                message.error("Could not move data to quarantine...")
                console.log(response);
            }
            setLoading(false)
            })
            .catch(error => {
                message.error("Something went wrong while moving the data to quarantine...")
                console.log(error);
                setLoading(false)
            });
      };

    const filterForm = 
        <Form layout="vertical" form={form}>
            <Row gutter={12} justify="space-between">
                <Col span={12} style={{whiteSpace: "nowrap"}}>
                    <Form.Item
                        label={
                            <Space size={8}>
                                <div>
                                    {/* <RedoOutlined onClick={() => resetFilter("accounts")} style={{marginRight: 4}}/>
                                    <ClearOutlined onClick={() => clearFilter("accounts")} style={{marginRight: 4}} /> */}
                                    <Text strong> E-mail date range </Text>
                                </div>
                                <div>
                                    <ClearResetFiltersButton 
                                        style={{marginRight: 4}} 
                                        onClick={() => resetFilter("content_types")}
                                        icon={<RedoOutlined />}
                                        buttonText="Reset"
                                    />
                                </div>
                            </Space>
                            // <React.Fragment><RedoOutlined onClick={() => resetFilter("date_range")} style={{marginRight: 4}}/> <Text strong>E-mail date range</Text></React.Fragment>
                        }
                    >
                        <span style={{display: "inline-block"}}>
                            <DatePicker
                                fullWidth
                                disabled={!(contentTypes.includes('attachment') || contentTypes.includes('body'))}
                                allowClear={false}
                                disabledDate={(current) => current && !!minFrom && !!dateTo && (current < moment(minFrom, 'DD/MM/YYYY').startOf('day') || current > moment(dateTo, 'DD/MM/YYYY').endOf('day'))}
                                defaultValue={dateFrom}
                                value={dateFrom}
                                format={dateFormat}
                                allowEmpty={false}
                                onChange={(date) => {setDateFrom(date);}}
                                style={{marginRight: 4}}
                            />
                        </span>
                        <span style={{display: "inline-block"}}>
                            <ArrowRightOutlined />
                        </span>
                        <span style={{display: "inline-block"}}>
                            <DatePicker
                                fullWidth
                                disabled={!(contentTypes.includes('attachment') || contentTypes.includes('body'))}
                                allowClear={false}
                                disabledDate={(current) => current && !!maxTo && !!dateFrom && (current > moment(maxTo, 'DD/MM/YYYY').endOf('day') || current < moment(dateFrom, 'DD/MM/YYYY').endOf('day'))}
                                value={dateTo}
                                format={dateFormat}
                                allowEmpty={false}
                                onChange={(date) => {setDateTo(date);}}
                                style={{marginLeft: 4}}
                            />
                        </span>
                    </Form.Item>
                </Col>
                <Col span={12} style={{whiteSpace: "nowrap"}}>
                    <Form.Item
                        name="content_types"
                        label={
                            <Space size={8}>
                                <div>
                                    {/* <RedoOutlined onClick={() => resetFilter("accounts")} style={{marginRight: 4}}/>
                                    <ClearOutlined onClick={() => clearFilter("accounts")} style={{marginRight: 4}} /> */}
                                    <Text strong> Content types </Text>
                                </div>
                                <div>
                                    <ClearResetFiltersButton 
                                        style={{marginRight: 4}} 
                                        onClick={() => resetFilter("content_types")} 
                                        icon={<RedoOutlined />}
                                        buttonText="Reset"
                                    />
                                    <ClearResetFiltersButton 
                                        style={{marginRight: 4}}
                                        onClick={() => clearFilter("content_types")}
                                        icon={<ClearOutlined />}
                                        buttonText="Clear"
                                    />
                                </div>
                            </Space>
                        }
                            // <React.Fragment><RedoOutlined onClick={() => resetFilter("content_types")} style={{marginRight: 4, whiteSpace: "nowrap"}}/><ClearOutlined onClick={() => clearFilter("content_types")} style={{marginRight: 4}} /><Text strong>Content types</Text></React.Fragment>
                    >
                        <span style={{display: "inline-block"}}>
                        <Checkbox 
                            checked={contentTypes.includes('body')}
                            onChange={e => {
                                if (e.target.checked) setContentTypes(ctypes => [...ctypes, 'body']) 
                                else setContentTypes(ctypes => ctypes.filter(val => val !== 'body'))
                            }} 
                        >
                            E-mail body
                        </Checkbox>
                        </span>
                        <span style={{display: "inline-block"}}>
                        <Checkbox 
                            checked={contentTypes.includes('attachment')}
                            onChange={e => {
                                if (e.target.checked) setContentTypes(ctypes => [...ctypes, 'attachment']) 
                                else setContentTypes(ctypes => ctypes.filter(val => val !== 'attachment'))
                            }} 
                        >
                            Attachment
                        </Checkbox>
                        </span>
                        <span style={{display: "inline-block"}}>
                        <Checkbox 
                            checked={contentTypes.includes('sharepoint')}
                            onChange={e => {
                                if (e.target.checked) setContentTypes(ctypes => [...ctypes, 'sharepoint']) 
                                else setContentTypes(ctypes => ctypes.filter(val => val !== 'sharepoint'))
                            }} 
                        >
                            Sharepoint
                        </Checkbox>
                        </span>
                        <span style={{display: "inline-block"}}>
                        <Checkbox 
                            checked={contentTypes.includes('onedrive')}
                            onChange={e => {
                                if (e.target.checked) setContentTypes(ctypes => [...ctypes, 'onedrive']) 
                                else setContentTypes(contentTypes.filter(val => val !== 'onedrive'))
                            }} 
                        >
                            OneDrive
                        </Checkbox>
                        </span>
                    </Form.Item>
                </Col>
            </Row>
            {true && <Divider style={{marginTop: -4, marginBottom: 16}}/>}
            {true && 
                <>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="accounts"
                                label={
                                    <Space size={8}>
                                        <div>
                                            {/* <RedoOutlined onClick={() => resetFilter("accounts")} style={{marginRight: 4}}/>
                                            <ClearOutlined onClick={() => clearFilter("accounts")} style={{marginRight: 4}} /> */}
                                            <Text strong> Accounts </Text>
                                        </div>
                                        <div>
                                            <ClearResetFiltersButton 
                                                style={{marginRight: 4}} 
                                                onClick={() => resetFilter("accounts")} 
                                                icon={<RedoOutlined />}
                                                buttonText="Reset"
                                            />
                                            <ClearResetFiltersButton 
                                                style={{marginRight: 4}}
                                                onClick={() => clearFilter("accounts")}
                                                icon={<ClearOutlined />}
                                                buttonText="Clear"
                                            />
                                            <FiltersTransfer 
                                                intFrameWidth={intFrameWidth}
                                                lookupData={(!!initialState && !!initialState.accounts) ? initialState.accounts : []}
                                                chosen={accounts}
                                                modalTitle="accounts"
                                                onAccept={setAccounts}
                                            />
                                        </div>
                                    </Space>
                                }
                            >
                                {/* <ComboBox 
                                    setFilters={setAccounts} 
                                    values={accounts} 
                                    lookupValues={(!!initialState && !!initialState.accounts) ? initialState.accounts : []}
                                /> */}
                                <>
                                    {accounts.slice(0, accounts.length - tagCountFilter >= 2 ? tagCountFilter : accounts.length).map(acc => {return <Tag style={{marginTop: 4}}>{acc}</Tag>})}
                                    {accounts.length - tagCountFilter >= 2 && <Tag style={{marginTop: 4}}>{"+ " + (Number(accounts.length) - tagCountFilter) + " others..."}</Tag>}
                                </>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="accounts_test"
                                label={
                                    <Space size={8}>
                                        <div>
                                            {/* <RedoOutlined onClick={() => resetFilter("accounts_test")} style={{marginRight: 4}}/>
                                            <ClearOutlined onClick={() => clearFilter("accounts_test")} style={{marginRight: 4}} /> */}
                                            <Text strong> Account contains (Select accounts if email contains input text) </Text>
                                        </div>
                                        <div>
                                            <ClearResetFiltersButton 
                                                style={{marginRight: 4}} 
                                                onClick={() => resetFilter("accounts_test")} 
                                                icon={<RedoOutlined />}
                                                buttonText="Reset"
                                            />
                                            <ClearResetFiltersButton 
                                                style={{marginRight: 4}}
                                                onClick={() => clearFilter("accounts_test")}
                                                icon={<ClearOutlined />}
                                                buttonText="Clear"
                                            />
                                        </div>
                                    </Space>
                                }
                            >
                                <Select mode="tags" placeholder="Type in multiple..." maxTagCount='responsive' value={accountsLike} style={{ width: '100%' }} onChange={e => setAccountsLike(e)} tokenSeparators={[',']}></Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            }
            <Divider style={{marginTop: -4, marginBottom: 16}}/>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="senders"
                        label={
                            <Space size={16}>
                                <div>
                                    {/* <RedoOutlined onClick={() => resetFilter("senders")} style={{marginRight: 4}}/>
                                    <ClearOutlined onClick={() => clearFilter("senders")} style={{marginRight: 4}} /> */}
                                    <Text strong> Senders </Text>
                                </div>
                                <div>
                                    <ClearResetFiltersButton 
                                        style={{marginRight: 4}} 
                                        onClick={() => resetFilter("senders")} 
                                        icon={<RedoOutlined />}
                                        buttonText="Reset"
                                    />
                                    <ClearResetFiltersButton 
                                        style={{marginRight: 4}}
                                        onClick={() => clearFilter("senders")}
                                        icon={<ClearOutlined />}
                                        buttonText="Clear"
                                    />
                                    <FiltersTransfer 
                                        intFrameWidth={intFrameWidth}
                                        lookupData={(!!initialState && !!initialState.senders) ? initialState.senders : []}
                                        chosen={senders}
                                        modalTitle="senders"
                                        onAccept={setSenders}
                                    />
                                </div>
                            </Space>
                        }
                    >
                        {/* <ComboBox 
                            disabled={!(contentTypes.includes('attachment') || contentTypes.includes('body'))} 
                            setFilters={setSenders} 
                            values={senders} 
                            lookupValues={(!!initialState && !!initialState.senders) ? initialState.senders : []}
                        /> */}
                        <>
                            {senders.slice(0, tagCountFilter).map(acc => {return <Tag style={{marginTop: 4}}>{acc}</Tag>})}
                            {senders.length > tagCountFilter && <Tag style={{marginTop: 4}}>{"+ " + (Number(senders.length) - tagCountFilter) + " others..."}</Tag>}
                        </>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xl={24} md={24}>
                    <Form.Item
                        name="sender_domains"
                        label={
                            <Space size={8}>
                                <div>
                                    {/* <RedoOutlined 
                                        onClick={() => resetFilter("sender_domains")}
                                        disabled={!(contentTypes.includes('attachment') || contentTypes.includes('body'))}
                                        style={{marginRight: 4}}
                                    />
                                    <ClearOutlined
                                        onClick={() => clearFilter("sender_domains")}
                                        disabled={!(contentTypes.includes('attachment') || contentTypes.includes('body'))}
                                        style={{marginRight: 4}} 
                                    /> */}
                                    <Text strong> Sender domains </Text>
                                </div>
                                <div>
                                    <ClearResetFiltersButton 
                                        style={{marginRight: 4}} 
                                        onClick={() => resetFilter("sender_domains")} 
                                        disabled={!(contentTypes.includes('attachment') || contentTypes.includes('body'))} 
                                        icon={<RedoOutlined />}
                                        buttonText="Reset"
                                    />
                                    <ClearResetFiltersButton 
                                        style={{marginRight: 4}}
                                        onClick={() => clearFilter("sender_domains")}
                                        disabled={!(contentTypes.includes('attachment') || contentTypes.includes('body'))}
                                        icon={<ClearOutlined />}
                                        buttonText="Clear"
                                    />
                                    <FiltersTransfer 
                                        intFrameWidth={intFrameWidth}
                                        lookupData={(!!initialState && !!initialState.senderDomains) ? initialState.senderDomains : []}
                                        chosen={senderDomains}
                                        modalTitle="sender domains"
                                        onAccept={setSenderDomains}
                                        disabled={!(contentTypes.includes('attachment') || contentTypes.includes('body'))}
                                    />
                                </div>
                            </Space>
                        }
                    >
                        {/* <ComboBox 
                            disabled={!(contentTypes.includes('attachment') || contentTypes.includes('body'))} 
                            setFilters={setSenderDomains} 
                            values={senderDomains} 
                            lookupValues={(!!initialState && !!initialState.senderDomains) ? initialState.senderDomains : []}
                        /> */}
                        <>
                            {senderDomains.slice(0, tagCountFilter).map(acc => {return <Tag style={{marginTop: 4}}>{acc}</Tag>})}
                            {senderDomains.length > tagCountFilter && <Tag style={{marginTop: 4}}>{"+ " + (Number(senderDomains.length) - tagCountFilter) + " others..."}</Tag>}
                        </>
                        
                  </Form.Item>
                </Col>
                <Col xl={24} md={24}>
                    <Form.Item
                        name="senders_test"
                        label={
                            <Space size={8}>
                                <div>
                                    {/* <RedoOutlined onClick={() => resetFilter("senders_test")} style={{marginRight: 4}}/>
                                    <ClearOutlined onClick={() => clearFilter("senders_test")} style={{marginRight: 4}} /> */}
                                    <Text strong> Sender contains  (selects senders containing given phrases)</Text>
                                </div>
                                <div>
                                    <ClearResetFiltersButton 
                                        style={{marginRight: 4}} 
                                        onClick={() => resetFilter("senders_test")} 
                                        disabled={!(contentTypes.includes('attachment') || contentTypes.includes('body'))} 
                                        icon={<RedoOutlined />}
                                        buttonText="Reset"
                                    />
                                    <ClearResetFiltersButton 
                                        style={{marginRight: 4}}
                                        onClick={() => clearFilter("senders_test")}
                                        disabled={!(contentTypes.includes('attachment') || contentTypes.includes('body'))}
                                        icon={<ClearOutlined />}
                                        buttonText="Clear"
                                    />
                                </div>
                            </Space>
                        }
                    >
                        <Select disabled={!(contentTypes.includes('attachment') || contentTypes.includes('body'))} mode="tags" maxTagCount='responsive' placeholder="Type in multiple..." value={sendersLike} style={{ width: '100%' }} onChange={e => setSendersLike(e)} tokenSeparators={[',']}></Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>

    const filterModal = 
        <Modal
            open={drawerVisible}
            title="Customize filters"
            onOk={() => setDrawerVisible(false)}
            onCancel={() => setDrawerVisible(false)}
            width={Math.max(intFrameWidth - 200, 400)}
            centered
            maskClosable={false}
            style={{height: 490, marginTop: -80}}
            footer={[
                <Row justify="space-between">
                    <Col>
                        {clearFiltersButton}
                        {resetFiltersButton}
                        {restoreFiltersButton}
                    </Col>
                    <Col>
                        {applyFiltersButton}
                    </Col>
                </Row>
            ]}
        >
            <Row gutter={16}>
                <Col span={18}>
                    <Card style={{overflowX: "auto", height: 434, maxHeight: 434}}>
                        {filterForm}
                    </Card>
                </Col>
                <Col span={6}>
                    <Card style={{overflowX: "auto", height: 434, maxHeight: 434}}>
                        <div style={{marginLeft: 6, marginTop: 8}}>
                            <Text strong>GDPR Classifications </Text>
                            <ClearResetFiltersButton 
                                style={{marginLeft: 4, marginRight: 4}} 
                                onClick={() => resetFilter("classifications")} 
                                icon={<RedoOutlined />}
                                buttonText="Reset"
                            />
                            <ClearResetFiltersButton 
                                style={{marginRight: 4}}
                                onClick={() => clearFilter("classifications")}
                                icon={<ClearOutlined />}
                                buttonText="Clear"
                            />
                        </div>
                        <Tree 
                            // treeData={tree_data}
                            treeData={filterTree}
                            onCheck={(checked, e) => {setClassifications(checked)}}
                            checkable={true}
                            style={{width: '100%', marginTop: 8}}
                            checkedKeys={classifications}
                            defaultExpandedKeys={["GDPR", "GDPR Sensitive", "GDPR Confidential"]}
                            selectable={false}
                        />
                    </Card>
                </Col>
            </Row>
        </Modal>
    
    const choice = () => {
        if (selected === 'report-view'){
            return <Report
                data={reportData}
                loading={loadingReport || initializing}
                drawerVisible={drawerVisible}
                drawerHeight={drawerHeight}
                filterDrawer={<FilterDrawer drawerVisible={drawerVisible} setDrawerVisible={setDrawerVisible}/>}
                generateCsv={generateCsv}
                handleDelete={handleDelete}
                // comboContentFilter={comboContentFilter}
                // themeSwitchButton={themeSwitchButton}
                // applyFiltersButton={applyFiltersButton}
                // resetFiltersButton={resetFiltersButton}
                // clearFiltersButton={clearFiltersButton}
                // showNonGdprMessages={showNonGdprMessages}
                // setShowNonGdprMessages={setShowNonGdprMessages}
                // showNonGdprComponents={showNonGdprComponents}
                // setShowNonGdprComponents={setShowNonGdprComponents}
                intFrameHeight = {intFrameHeight}
                intOuterFrameHeight = {intOuterFrameHeight}
                accountSubTable={record => {
                    return <AccountSubTable record={record}
                        // dateFrom={dateFrom}
                        // dateTo={dateTo}
                        // areas={areas}
                        // categories={categories}
                        // classifications={classifications}
                        // contentTypes={contentTypes}
                        filterState={filterState}
                        filtersAppliedFlag={filtersAppliedFlag}
                        triggerApplyFilters={triggerApplyFilters}
                        intFrameHeight={intFrameHeight}
                        intOuterFrameHeight={intOuterFrameHeight}
                        showNonGdprComponents={showNonGdprComponents}
                        blockedViewRightsAcc={blockedViewRightsAcc}
                    />
                }}
            />
        }
        if (selected === 'graphical-view'){
            return <Overview
                loading={loading || initializing}
                loadingSub={loadingSub}
                loadingData={loadingData}
                intFrameHeight={intFrameHeight}
                // themeSwitchButton={themeSwitchButton}
                clickUserPeriodCategory={clickUserPeriodCategory}
                initData={initData}
                data={overviewData}
                graphData={graphData}
                subGraphData={subGraphData}
                fetchData={fetchOverviewData}
                fetchDataFiltered={fetchOverviewDataFiltered}
                fetchSubGraphData={fetchSubGraphData}
                fetchOverviewFiltered={fetchOverviewFiltered}
                // TODO controls for graph clicking - rework
                setData={setOverviewData}
                setGraphData={setGraphData}
                setSubGraphData={setSubGraphData}
                generateCsv={generateCsv}
            />
        } if (selected === 'admin-view'){
            return <AdminView 
                intFrameHeight={intFrameHeight}
                intFrameWidth={intFrameWidth}
                dateFormat={dateFormat}
                minFrom={minFrom}
                maxTo={maxTo}
            />
        }
    };

    return (
        <React.Fragment>
        {contextHolder}
        {initializing
            ?
                <span style={{textAlign: "center", display: "block", paddingTop: 0.3*intFrameHeight}}>
                    <Spin spinning={initializing} tip="Initializing report..." style={{marginTop: -20}}>
                        {currentTheme === 'dark'
                            ? <img src='./images/CompliancePurpleOutlined.png' style={{minWidth:200, minHeight:200}} />
                            : <img src='./images/CompliancePurple.png' style={{minWidth:200, minHeight:200}} />}
                    </Spin>
                </span>
            :
                <Layout style={{minWidth: 800, minHeight: 400}}>
                    <Header
                        style={ currentTheme === 'dark'
                            ? { position: 'fixed', height: appBarHeight, zIndex: 1, width: '100%', padding: 0, borderBottom:"1px solid #555", overflowX: 'hidden', overflowY: 'hidden' }
                            : { position: 'fixed', height: appBarHeight, zIndex: 1, width: '100%', padding: 0, background: "#fff", borderBottom:"1px solid #ccc", overflowX: 'hidden', overflowY: 'hidden'}}
                    >
                        <Row gutter={4} justify="space-between">
                            <Col>
                                <Row>
                                    <Col style={{marginLeft: 4}}>
                                        {currentTheme === 'dark'
                                            ? <img src='./images/CompliancePurpleOutlined.png' style={{maxWidth:90, maxHeight:90}} />
                                            : <img src='./images/CompliancePurple.png' style={{maxWidth:90, maxHeight:90}} />}
                                    </Col>
                                    <Col>
                                        <Title level={3} style={currentTheme === 'dark' ? {marginTop: 5, marginBottom: 0} : {color: "#54146b", marginTop: 5, marginBottom: 0}}>
                                            Sixtus
                                        </Title>
                                        <Title level={4} style={currentTheme === 'dark' ? {marginTop: -5} : {color: "#54146b", marginTop: -5}}>
                                            Technologies
                                        </Title>
                                    </Col>
                                    {false && <Col style={{marginLeft: 4}}>
                                        <img src='./images/AngePurpleSmooth.png' style={{maxWidth:50, maxHeight:50}} />
                                    </Col>}
                                    {false && <Col style={{marginLeft: 4}}>
                                        <img src='./images/outlook.jpg' style={{maxWidth:60, maxHeight:60}} />
                                    </Col>}
                                </Row>
                            </Col>
                            {intFrameWidth > 1270 && <Col style={{marginRight: 16, marginTop: 4}}>
                                <Statistics loading={initializing || loadingStatistics} statistics={statistics}/>
                            </Col>}
                            <Col style={{marginRight: 8, marginTop: 4, marginLeft: 0, paddingLeft: 0}}>
                            <Row style={{marginBottom: 0, paddingBottom: 0}}>
                                    <Col style={{marginRight: 0, marginBottom: 0, paddingBottom: 0, paddingRight: 5, paddingTop: 5}}>
                                        <ViewDataWarning superusers={viewDataSuperusers} />
                                    </Col>
                                    <Col style={{marginBottom: 0, paddingBottom: 0}}>
                                        <UserLogoutButton 
                                            //   themeSwitchButton={themeSwitchButton}
                                            changeRole={(role) => {
                                                setRole(role);
                                                setRoute(role === 'admin' ? "api/master" : "api/user");
                                                setReportData([]); 
                                                fetchInitialData(role === 'admin' ? "api/master" : "api/user"); 
                                                setSelected("graphical-view")
                                                }}
                                                lastScanDate={role === 'admin' 
                                                    ? statistics?.last_scan_date
                                                    ? statistics.last_scan_date
                                                    : "------"
                                                    : statistics?.user_scan_date
                                                    ? statistics.user_scan_date
                                                    : statistics?.last_scan_date
                                                    ? statistics.last_scan_date
                                                    : "------"
                                                } 
                                                />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Header>
                    <Layout>
                        <Sider
                            collapsible
                            collapsed={collapsed}
                            onCollapse={onCollapse}
                            theme={currentTheme === 'dark' ? "dark" : "light"}
                            style={currentTheme === 'dark' 
                                ? {marginTop: 68, height: intFrameHeight - 68, maxHeight: intFrameHeight - 68, overflowX: "auto"}
                                : {marginTop: 68, height: intFrameHeight - 68, maxHeight: intFrameHeight - 68, overflowX: "auto"}
                            }
                        >
                            <Menu selectedKeys={[selected]} theme={currentTheme === 'dark' ? "dark" : "light"}
                                defaultSelectedKeys={['graphical-viewview']} mode="vertical" onClick={handleClick}
                                onOpenChange={onOpenChange} openKeys={openKeys}
                            >
                                <Menu.Item key="graphical-view" icon={<PieChartOutlined />}>
                                    Overview
                                </Menu.Item>
                                <Menu.Item key="report-view" icon={<FileSearchOutlined />}>
                                    Report
                                </Menu.Item>
                                {(role === "admin" || window.o365_config.USE_DEVELOPMENT_BUILD) && <Menu.Item key="admin-view" icon={<SettingOutlined />}>
                                    Configuration
                                </Menu.Item>}
                            </Menu>
                        </Sider>
                        <Layout style={currentTheme === 'dark' ? {borderLeft:"1px solid #555"} : {borderLeft:"1px solid #ccc"}}>
                            <Layout>
                                <Layout style={currentTheme === 'dark' 
                                    ? {marginTop: 68} 
                                    : {marginTop: 68}
                                }>
                                    <Content style={!drawerVisible
                                        ? { padding: 8, height: intFrameHeight - 68, maxHeight: intFrameHeight - 68, overflow: "auto" }
                                        : currentTheme === 'dark' 
                                            ? { padding: 8, height: intFrameHeight - 68 - drawerHeight, maxHeight: intFrameHeight - 68 - drawerHeight, overflow: "auto", borderBottom:"1px solid #555", borderRight:"1px solid #555" } 
                                            : { padding: 8, height: intFrameHeight - 68 - drawerHeight, maxHeight: intFrameHeight - 68 - drawerHeight, overflow: "auto", borderBottom:"1px solid #ccc", borderRight:"1px solid #ccc" }}>
                                        {choice()}
                                        {selected === 'report-view' && filterModal}
                                    </Content>
                                </Layout>
                            </Layout>
                        </Layout>
                    </Layout>
                </Layout>
        }
        </React.Fragment>
    );
}

